import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View,TouchableOpacity } from 'react-native';
import {Button, Divider, IconButton, List, Uploader} from 'rsuite'
import '../app.css';
import { useState ,useEffect} from 'react';
import CameraRetroIcon from '@rsuite/icons/legacy/CameraRetro';
import 'rsuite/Uploader/styles/index.css';
import 'rsuite/IconButton/styles/index.css';

import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getApp } from "firebase/app";
import { getStorage , ref, uploadBytes} from "firebase/storage";
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faCircleUp } from '@fortawesome/free-regular-svg-icons';

// Get a non-default Storage bucket

const firebaseConfig = {
  apiKey: "AIzaSyA14GLLM3NVuLQRn3ySAOixAoGT73dM0bQ",
  authDomain: "iremokan-b74d1.firebaseapp.com",
  projectId: "iremokan-b74d1",
  storageBucket: "iremokan-b74d1.appspot.com",
  messagingSenderId: "246411202229",
  appId: "1:246411202229:web:4196babd42f4343f9678fc",
  measurementId: "G-GJD7L6MTBW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firebaseApp = getApp();
const storage = getStorage(firebaseApp, "gs://iremokan-b74d1.appspot.com");

export default function IremOkan() {
  const [images, setImageSource] = useState([]);

  useEffect(() => {
    console.log(images);
  }, [images]);

  // firebase storage load images
  function loadImages() {

    let count = 0;
    images.forEach((image) => {
        const storageRef = ref(storage, 'iremokan' + Date.now()+ image.name + "_" + count);
        uploadBytes(storageRef, image.blobFile).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        }
        );
        count++;
    }
    );

    setImageSource([]);
    alert("Images are uploaded successfully!");
  }



  return (

    <View style={styles.container}>
    <View style={styles.borderThin}>
      <View style={styles.borderThick}>
        <View style={styles.borderContainer}>
          <View style={styles.headerPanel}>
              <Text style ={styles.header}>
                  İrem & Okan
              </Text>
          </View>
            <LineDivider></LineDivider>
            <Text style={{fontSize: 20,fontFamily: 'grandiflora', color: '#B4B4B8'}}>Choose your photos</Text>
            <Uploader multiple fileListVisible = {false} listType='picture' onChange={(file) => {
                setImageSource(file);
            }
            }>
              <CameraRetroIcon></CameraRetroIcon>

            </Uploader>
            <LineDivider></LineDivider>
            {/* <Button onClick={loadImages} color="blue" style={{backgroundColor:'#fff',  }}> <Text style={{color: '#B4B4B8', fontFamily: 'rudge'}}>Upload</Text></Button> */}
            <IconButton icon ={<Icon style={styles.iconButton} ><FontAwesomeIcon icon={faCircleUp}></FontAwesomeIcon></Icon>} onClick={loadImages} style={styles.iconButton} > <Text style={{color: '#fff', fontWeight: 600, fontFamily: 'grandiflora'}}>Upload</Text></IconButton>
            <View style = {{height: 25}}></View>
            <View
                 style={styles.imageLoader}>
                <Carousel fade style={{width: '100%' , height: '100%', backgroundColor: '#FEFAF6'}}>
                    {
                        images.map((image, index) => {
                            return (
                              <Carousel.Item>
                                <Carousel.Caption>
                                    <h3>{image.name}</h3>
                                </Carousel.Caption>
                                    <Image
                                    style={{width: '100%', height: '100%'}}
                                        thumbnail
                                        src={URL.createObjectURL(image.blobFile)}
                                    />
                                </Carousel.Item>
                            );
                        })
                    }
                </Carousel>
            </View>
  
          </View>
      </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFAF6',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
    
  },
  iconButton:{
    color: '#F2EFE5',
    backgroundColor: '#DAC0A3',
    borderColor: '#F3D0D7',
    borderWidth: 1
  },
  borderThick: {
    padding: 3,
    flex: 1, 
    width: '100%',
    borderWidth: 3,
    borderColor:'#B4B4B8',
    alignItems: 'center',
  },
  borderThin: {
    padding: 3,
    flex: 1, 
    width: '100%',
    borderWidth: 1,
    borderColor:'#B4B4B8',
    alignItems: 'center',
  },
  imageLoader:{
    padding: 20,
    width: '85%',
    flex: 1,
    borderWidth: 1,
    borderColor:'#B4B4B8',
    borderStyle: 'dashed',
    alignItems: 'center',
    justifyContent :'center'
  },
  borderContainer:{
    padding: 20,
    paddingTop: 35,
    flex: 1, 
    width: '100%',
    borderWidth: 1,
    borderColor:'#B4B4B8',
    alignItems: 'center',
    justifyContent: 'flex-start',

  },
  header:{
      fontFamily: 'grandiflora',
      fontSize: 48,
      color: '#B4B4B8'
  },
   thumbnail: {
    width: 300,
    height: 300,
    margin: 5,
    borderRadius: 5,
    overflow: 'hidden',
  },
  thumbnailHover: {
    borderWidth: 2,
    borderColor: '#aaa',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayText: {
    color: 'white',
    fontSize: 16,
  },
});



const LineDivider = () =>{
    return(
      <View style={{
        width: '80%', 
        marginVertical: 20,
        height : 1, 
        backgroundColor: '#B4B4B8'
      }} >
      </View>
    )
}