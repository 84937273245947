import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View,TouchableOpacity } from 'react-native';
import './app.css';
import { useState ,useEffect} from 'react';
import IremOkan from './src/Iremokan';
import Home from './src/Home';
import { Image } from 'react-bootstrap';
import { Button, Drawer, Input, List, Grid, Row, Col, Divider } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";


// Get a non-default Storage bucket

const firebaseConfig = {
  apiKey: "AIzaSyA14GLLM3NVuLQRn3ySAOixAoGT73dM0bQ",
  authDomain: "iremokan-b74d1.firebaseapp.com",
  projectId: "iremokan-b74d1",
  storageBucket: "iremokan-b74d1.appspot.com",
  messagingSenderId: "246411202229",
  appId: "1:246411202229:web:4196babd42f4343f9678fc",
  measurementId: "G-GJD7L6MTBW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp, "gs://iremokan-b74d1.appspot.com");

const analytics = getAnalytics(app);
const firebaseApp = getApp();


function App() {

  const [show, setShow] = useState(false);

  useEffect(() => {
    console.log(show);
  }, [show]);
  return (
    <View style={styles.container}>
      <Drawer placement="right" open={show} onClose={() => setShow(!show)} size={'full'}>
          <Drawer.Header>
            <Drawer.Title>Login</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body style={{backgroundColor: '#FEFAF6'}}>
            <LoginModal></LoginModal>
          </Drawer.Body>
      </Drawer>
      <View style={{backgroundColor: '#FEFAF6', width: '100%', flexDirection: 'row' , justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 10, marginTop: 10}}>
        <Image src = {require('./assets/icon.png')} style={{width: 30, height: 30}} onClick={() => setShow(!show)}></Image>
        <Text style={{fontSize: 16, marginLeft: 5 , fontFamily: 'rudge', color: '#B4B4B8'}}>lensflock</Text>
      </View>
      <IremOkan></IremOkan>
    </View>
  );
}

const LoginModal = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedin, setisLoggedin] = useState(false)

  function handleFirebaseLogin() {
    const auth = getAuth(firebaseApp);
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      console.log(user);
      setisLoggedin(true);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage);
      // ..
    });
  }


  return (
    <View>
      {isLoggedin ? <AllImages></AllImages>
      : 
        <View style={{backgroundColor: '#FEFAF6', flex : 1, justifyContent: 'center', alignItems: 'center'}}>
        <Input placeholder="Username" style={{width: 200}} onChange={(value) => setEmail(value)} />
        <View style={{height: 10}}></View>
        <Input placeholder="Password" style={{width: 200}} onChange={(value) => setPassword(value)} />
        <View style={{height: 10}}></View>
        <Button style={{width: 200 ,backgroundColor: '#DAC0A3', color: '#F2EFE5', borderColor: '#F3D0D7', borderWidth: 1}} onClick={handleFirebaseLogin}>Login</Button>
    </View>
      }
    </View>
  
  )
}


const AllImages = () => {
  const [images, setImages] = useState([]);


  async function getImages() {
    const storageRef = ref(storage, '/');
    const res = await listAll(storageRef);
    console.log(res.items);
    const fetchImageUrls = res.items.map(async (imageRef) => {
      const url = await getDownloadURL(imageRef);
      return { fullPath: url };
    });

    const imageUrls = await Promise.all(fetchImageUrls);
    console.log(imageUrls);
    setImages(imageUrls);
  }

  return (
    <View>
      <Button style={{width: 200 ,backgroundColor: '#DAC0A3', color: '#F2EFE5', borderColor: '#F3D0D7', borderWidth: 1}} onClick={() => getImages()}>Get Images</Button>
      <Divider></Divider>
      <Grid fluid>
        <Row gutter={10}>
          {images.map((image) => (
            <Col xs={6} md={4}>
              <Image src={image.fullPath} style={{width: 100}}></Image>
              <View style={{height: 20}}></View>
            </Col>
          ))}
        </Row>
      </Grid>
    </View>
  );
};


export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFAF6',
  },
  
});

