import { Text, View } from 'react-native'
import React, { PureComponent } from 'react'

export default class Home extends PureComponent {
  render() {
    return (
      <View>
        <Text>Home</Text>
      </View>
    )
  }
}